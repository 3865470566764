import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { Skeleton } from 'antd';
import { RootState } from 'infrastructure/interfaces/adapters/store/rematch';
import { ResponsiveWrapper } from './style';
import { StatisticServicedByCarBrand } from 'domain/entities';

function ByCarBrand() {
  // STORE
  const data: StatisticServicedByCarBrand[] = useSelector(
    (state: RootState) => state.statistic.data.servicedByCarBrand
  );
  const loading: boolean = useSelector(
    (state: RootState) => state.loading.effects.statistic.getItem
  );

  // IN CASE LOADING
  if (loading) return <Skeleton active />;

  // VARIABLE
  const colors = [
    '#A891D4',
    '#DEBB9A',
    '#F7AABD',
    '#FCF1C3',
    '#D6D6D4',
    '#A4D5AE',
    '#FCD04D',
    '#F8EACD',
    '#FCF688',
    '#A1C0D7',
  ];
  const chartData = data.map((r) => ({
    name: r.title,
    value: r.total,
  }));

  return (
    <ResponsiveWrapper>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={chartData}
            label={({ name }) => name}
            outerRadius={150}
          >
            {chartData.map((r, i) => (
              <Cell key={i} fill={colors[i % colors.length]} />
            ))}
          </Pie>
          <Tooltip formatter={(v: string) => v.toLocaleString()} />
        </PieChart>
      </ResponsiveContainer>
    </ResponsiveWrapper>
  );
}

export default memo(ByCarBrand);
