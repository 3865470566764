export class Statistic {
  constructor(readonly total: number) { }
}

export class StatisticServicedInDaily extends Statistic {
  constructor(readonly date: Date, _total: number) {
    super(_total);
  }
}

export class StatisticServicedInMonthly extends Statistic {
  constructor(readonly month: Date, _total: number) {
    super(_total);
  }
}

export class StatisticServicedByCarBrand extends Statistic {
  constructor(readonly title: string, _total: number) {
    super(_total);
  }
}
