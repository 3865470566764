import HttpClient from 'infrastructure/interfaces/adapters/http_client';

export default abstract class BaseRepository {
  constructor(public httpClient: HttpClient) {}
}

export interface BaseListInput {
  search?: string;
  start?: number;
  limit?: number;
}
