import { User, UserCredential } from 'domain/entities';
import HttpClient from 'infrastructure/interfaces/adapters/http_client';
import AuthRepository from 'infrastructure/repositories/auth';
import { DispatchState } from '.';

export interface IAuthStore {
  user: User | null;
}

const stateInitial: IAuthStore = {
  user: null,
};

export default function authModel(httpClient: HttpClient) {
  const authRepo = new AuthRepository(httpClient);
  return {
    state: stateInitial,
    reducers: {
      setItem: (state: IAuthStore, payload: User): IAuthStore => ({
        user: payload,
      }),
      unsetItem: () => stateInitial,
    },
    effects: (dispatch: DispatchState) => ({
      async getOverView() {
        try {
          const data = await authRepo.getOverView();

          // set auth
          dispatch({ type: 'auth/setItem', payload: data.user });
          // set car brand
          dispatch({ type: 'carBrand/setItem', payload: data.carBrands });

          // call statistic
          dispatch.statistic.getItem();
        } catch {
          localStorage.removeItem('token');
        } finally { }
      },
      async signIn(params: UserCredential) {
        try {
          await authRepo.signIn(params);

          localStorage.setItem('token', 'cookie-around-here'); // mock cookie because PHP APIs with use session instead
          dispatch({ type: 'auth/getOverView' });
        } catch (e) {
          return e;
        } finally { }

        return null;
      },
      async signOut() {
        try {
          await authRepo.signOut();

          localStorage.removeItem('token');
          dispatch({ type: 'auth/unsetItem' });
        } finally { }
      },
    }),
  };
}
