import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Card, Col, Dropdown, Menu, Modal, Row } from 'antd';
import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { BaseCustomer } from 'domain/entities';
import {
  dispatch,
  RootState,
} from 'infrastructure/interfaces/adapters/store/rematch';
import { Breadcrumbs, IBreadcrumb, modalGoBack } from 'presentation/components';
import CarListing from './car';
import Form from './Form';
import ServiceListing from './service';

function Detail({ match, history }: RouteComponentProps) {
  // STORE
  const row: BaseCustomer | null = useSelector((state: RootState) => state.customer.row);

  // I18N
  const { t } = useTranslation();

  // VARIABLE
  const creating = match.path === '/customers/create';
  const layout = { xs: 24, lg: 12 };
  const breadcrumbs: IBreadcrumb[] = [
    { title: t('customer.title'), link: '/' },
  ];

  // STATE
  const [editing, setEditing] = useState<boolean>(creating);

  // AS MOUNTED AND
  useEffect(() => {
    const id = get(match, 'params.id', '');
    if (id) {
      dispatch.customer.getRow(id).then((res: boolean) => {
        if (!res) {
          modalGoBack(`/customers`);
        }
      });
    }
    return () => {
      dispatch.customer.unsetRowItem();
    };
  }, [match]);

  useEffect(() => {
    if (creating && row) {
      history.push(`/customers/${row.id}`);
    }
  });

  const confirmDelete = () => {
    Modal.confirm({
      title: t('customer.detail.modal.delete.title', { title: row?.title }),
      okText: t('customer.detail.modal.delete.button.ok'),
      cancelText: t('customer.detail.modal.delete.button.cancel'),
      async onOk() {
        const res = await dispatch.customer.delete(row?.id as string);
        if (res) {
          history.push(`/customers`);
        }
      },
    });
  };

  // ADD MORE BREADCRUMB
  if (row) {
    breadcrumbs.push({
      title: row.getLongTitle(),
    });
  } else if (creating) {
    breadcrumbs.push({
      title: t('customer.detail.create'),
    });
  }

  const customerOption = (
    <Menu>
      <Menu.Item key="1" disabled={editing} onClick={() => setEditing(true)}>
        <EditOutlined /> {t('customer.detail.option.edit')}
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => confirmDelete()}
        style={{ color: '#ff4d4f' }}
      >
        <DeleteOutlined /> {t('customer.detail.option.delete')}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Breadcrumbs data={breadcrumbs} />
      <Row gutter={16}>
        <Col {...layout}>
          <Card
            title={t('customer.detail.title')}
            extra={
              row && (
                <Dropdown overlay={customerOption}>
                  <MoreOutlined />
                </Dropdown>
              )
            }
          >
            <Form editing={editing} onToggleEdit={setEditing} />
          </Card>
          {row && (
            <Card title={t('customer.car.title')}>
              <CarListing />
            </Card>
          )}
        </Col>
        <Col {...layout}>
          {row && (
            <Card title={t('customer.service.title')}>
              <ServiceListing />
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
}

export default memo(Detail);
