export class Pagination {
  public limit: number = 10;
  public start: number = 0;
  public total: number = 0;
  public current: number = 1;
  constructor(args?: Partial<Pagination>) {
    this.limit = args?.limit || this.limit;
    this.start = args?.start || this.start;
    this.total = args?.total || this.total;
    this.current = args?.current || this.current;
    // IN CASE, CALCULATE FROM PAGE
    if (args?.current) {
      this.start = (args?.current - 1) * (args?.limit || 0);
    }
  }

  getCurrentPage(): number {
    if (this.total === 0) {
      return 1;
    }
    if (this.start === 0) {
      return 1;
    }
    return Math.ceil(this.start / this.limit) + 1;
  }
}
