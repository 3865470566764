import {
  Response,
  StatisticServicedByCarBrand,
  StatisticServicedInDaily,
  StatisticServicedInMonthly,
} from 'domain/entities';
import {
  StatisticServicedInDailyModel,
  StatisticServicedInMonthlyModel,
  StatisticServicedByCarBrandModel,
} from 'infrastructure/models';
import BaseRepository from './base';

export default class StatisticRepository extends BaseRepository {
  async getAll() {
    const res = await this.httpClient.request<
      Response<{
        servicedInDaily: StatisticServicedInDailyModel[];
        servicedInMonthly: StatisticServicedInMonthlyModel[];
        servicedByCarBrand: StatisticServicedByCarBrandModel[];
      }>
    >({
      url: '/statistics',
      method: 'GET',
    });

    const data = res.data.data;

    return {
      servicedInDaily: data?.servicedInDaily.map(
        (r) => new StatisticServicedInDaily(r.date, r.total)
      ),
      servicedInMonthly: data?.servicedInMonthly.map(
        (r) => new StatisticServicedInMonthly(r.month, r.total)
      ),
      servicedByCarBrand: data?.servicedByCarBrand.map(
        (r) => new StatisticServicedByCarBrand(r.title, r.total)
      ),
    };
  }
}
