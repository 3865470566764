import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { dispatch } from 'infrastructure/interfaces/adapters/store/rematch';
import { hasErrors, rules } from 'utils';
import { UserCredential } from 'domain/entities';

function FormModal() {
  // i18N
  const { t } = useTranslation();

  // METHOD
  const handleSubmit = (val: any) => {
    dispatch.auth.signIn(new UserCredential(val.username, val.password));
  };

  return (
    <Form name="signIn" onFinish={handleSubmit}>
      <Form.Item
        name="username"
        rules={[
          rules('requiredWithOutWhitespace', t('signIn.form.label.username')),
          rules('typeEmail'),
        ]}
      >
        <Input
          prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="example@mail.com"
          autoComplete="off"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[rules('required', t('signIn.form.label.password'))]}
      >
        <Input
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          type="password"
          placeholder="********"
        />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          xs: { span: 24, offset: 0 },
          sm: { span: 12, offset: 6 },
        }}
        shouldUpdate
      >
        {({ getFieldsError }) => (
          <Button
            type="primary"
            htmlType="submit"
            block
            disabled={hasErrors(getFieldsError())}
          >
            {t('signIn.form.button.submit')}
          </Button>
        )}
      </Form.Item>
    </Form>
  );
}

export default memo(FormModal);
