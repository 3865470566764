import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Form from './Form';
import { ButtonSignIn } from './style';

function SignIn() {
  // i18N
  const { t } = useTranslation();

  // STATE
  const [visible, setVisible] = useState(false);

  return (
    <>
      <ButtonSignIn onClick={() => setVisible(true)}>
        <UserOutlined />
      </ButtonSignIn>
      <Modal
        centered
        closable={false}
        footer={null}
        onCancel={() => setVisible(false)}
        title={t('signIn.title')}
        visible={visible}
      >
        <Form />
      </Modal>
    </>
  );
}

export default memo(SignIn);
