import styled from 'styled-components';
import theme from 'assets/theme.json';

export const ButtonSignIn = styled.button`
  background: none ${theme.primary};
  border: 0px;
  margin: 10px;
  padding: 0px;
  position: absolute;
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  height: 40px;
  width: 40px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  overflow: hidden;
  top: 0px;
  right: 0px;
  color: white;
`;
