import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Input, Row, Skeleton } from 'antd';
import {
  MinusCircleOutlined,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { BaseCustomer, CustomerRow } from 'domain/entities';
import {
  dispatch,
  RootState,
} from 'infrastructure/interfaces/adapters/store/rematch';
import { formHorizontal, LoadingWrapper } from 'presentation/components';
import { hasErrors, rules } from 'utils';

interface CustomerFormPropTypes {
  editing: boolean;
  onToggleEdit: (editing: boolean) => void;
}

function CustomerForm({ editing, onToggleEdit }: CustomerFormPropTypes) {
  // STORE
  const loading: boolean = useSelector((state: RootState) => state.loading.effects.customer.getRow);
  const submitting: boolean = useSelector(
    (state: RootState) => state.loading.effects.customer.create || state.loading.effects.customer.update
  )
  const data: CustomerRow | null = useSelector(
    (state: RootState) => state.customer.row
  );

  // i18N
  const { t } = useTranslation();

  // VARIABLE
  const [form] = Form.useForm();

  const handleSubmit = async (val: any) => {
    let res;
    const upsert = new BaseCustomer(val);
    if (data?.id) {
      res = await dispatch.customer.update({ id: data.id, params: upsert });
    } else {
      res = await dispatch.customer.create(upsert);
    }

    if (res) onToggleEdit(false);
  };
  const handleCancel = () => {
    form.resetFields();
    onToggleEdit(false);
  };

  if ((!editing || loading) && !data) {
    return <Skeleton />;
  }

  return (
    <fieldset disabled={!editing}>
      <LoadingWrapper disabled={submitting}>
        <Form
          {...formHorizontal.input}
          form={form}
          initialValues={{
            ...data,
            title: data?.title || 'คุณ',
          }}
          name="customer"
          onFinish={handleSubmit}
        >
          {data?.code && (
            <Form.Item label={t('customer.detail.form.label.code')}>
              <span className="ant-form-text">{data.code}</span>
            </Form.Item>
          )}
          <Form.Item label={t('customer.detail.form.label.title')} name="title">
            <Input
              placeholder={t('customer.detail.form.placeholder.title')}
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item
            label={t('customer.detail.form.label.address')}
            name="address"
          >
            <Input.TextArea
              placeholder={t('customer.detail.form.placeholder.address')}
              autoComplete="off"
            />
          </Form.Item>
          <Form.List name="phones">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, i) => (
                  <Form.Item
                    key={field.key}
                    label={i === 0 ? t('customer.detail.form.label.phone') : ` `}
                    colon={i === 0}
                  >
                    <Form.Item
                      {...field}
                      rules={[rules('patternPhoneNumber')]}
                      noStyle
                    >
                      <Input
                        placeholder="0641058333"
                        autoComplete="off"
                        style={{
                          width: 'calc(100% - 8px - 14px)',
                          marginRight: 8,
                        }}
                      />
                    </Form.Item>
                    {editing && fields.length > 1 ? (
                      <MinusCircleOutlined
                        onClick={() => remove(field.key)}
                        style={{ cursor: 'pointer' }}
                      />
                    ) : (
                      <span />
                    )}
                  </Form.Item>
                ))}
                {editing && (
                  <Form.Item label=" " colon={false}>
                    <Button type="dashed" block onClick={() => add()}>
                      <PlusOutlined />{' '}
                      {t('customer.detail.form.button.morePhone')}
                    </Button>
                  </Form.Item>
                )}
              </>
            )}
          </Form.List>
          <Form.Item
            label={t('customer.detail.form.label.email')}
            name="email"
            rules={[rules('typeEmail')]}
          >
            <Input placeholder="example@mail.com" autoComplete="off" />
          </Form.Item>
          {editing && (
            <Form.Item {...formHorizontal.button}>
              <Row>
                <Col xs={12}>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldsError }) => (
                      <Button
                        type="primary"
                        htmlType="submit"
                        icon={<SaveOutlined />}
                        block
                        disabled={hasErrors(getFieldsError())}
                      >
                        {t('customer.detail.form.button.submit')}
                      </Button>
                    )}
                  </Form.Item>
                </Col>
                {!!data?.id && (
                  <Col xs={12}>
                    <Button type="link" block onClick={handleCancel}>
                      {t('customer.detail.form.button.cancel')}
                    </Button>
                  </Col>
                )}
              </Row>
            </Form.Item>
          )}
        </Form>
      </LoadingWrapper>
    </fieldset>
  );
}

export default memo(CustomerForm);
