import { BaseCustomer, CustomerCar, CustomerList, CustomerRow, CustomerService, Pagination, Response, ResponseIncluded, ResponseList } from 'domain/entities';
import { CustomerModel, CustomerCarModel, CustomerServiceModel } from 'infrastructure/models';
import BaseRepository, { BaseListInput } from './base';

export default class CustomerRepository extends BaseRepository {
  async getList(params: BaseListInput) {
    const res = await this.httpClient.request<ResponseList<CustomerModel[]>>({
      url: '/customers',
      method: 'GET',
      data: params,
    });

    const { data, pagination } = res.data;

    return {
      data: data?.map((r) => new CustomerList(r)),
      filter: {
        search: params.search,
      },
      pagination: new Pagination({
        start: pagination.start,
        limit: pagination.limit,
        total: pagination.total,
      }),
    };
  }
  async getRow(id: string) {
    const res = await this.httpClient.request<
      ResponseIncluded<
        CustomerModel,
        {
          cars: CustomerCarModel[];
          services: CustomerServiceModel[];
        }
      >
    >({
      url: `/customers/${id}`,
      method: 'GET',
    });
    const { data, included } = res.data;

    return new CustomerRow({
      ...data,
      cars: included.cars.map((r) => new CustomerCar(r)),
      services: included.services.map((r) => new CustomerService(r as CustomerService)),
    });
  }
  async create(params: BaseCustomer) {
    const res = await this.httpClient.request<Response<CustomerModel>>({
      url: '/customers',
      method: 'POST',
      data: params.toAPIData(),
    });
    const { data } = res.data;

    return new BaseCustomer(data);
  }
  async delete(id: string) {
    await this.httpClient.request<Response<unknown>>({
      url: `/customers/${id}`,
      method: 'DELETE',
    });
  }
  async update(id: string, params: BaseCustomer) {
    const res = await this.httpClient.request<Response<CustomerModel>>({
      url: `/customers/${id}`,
      method: 'PATCH',
      data: params.toAPIData(),
    });

    const { data } = res.data;

    return new BaseCustomer(data);
  }
}
