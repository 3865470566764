import { memo } from 'react';
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import { Container, Loading, MapEle, MapWrapper } from './style';

import SignIn from './form';

const located = { lat: 12.594204, lng: 102.1175682 };

function Map() {
  const googleMapURL =
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyBn7AdrR4QPk_AwLHA-7vejvBxHJAfrABE';

  return (
    <MapWrapper>
      <MapWithOther
        containerElement={<Container />}
        googleMapURL={googleMapURL}
        loadingElement={<Loading />}
        mapElement={<MapEle />}
      />
      <SignIn />
    </MapWrapper>
  );
}

export default memo(Map);

// SUB COMPONENT
const MapWithOther = withScriptjs(
  withGoogleMap(
    memo(() => (
      <GoogleMap
        defaultCenter={located}
        defaultZoom={18}
        options={{
          draggable: false,
          fullscreenControl: false,
          mapTypeControl: false,
          rotateControl: false,
          scaleControl: false,
          streetViewControl: false,
        }}
      >
        <Marker
          onClick={() =>
            window.open(
              'https://www.google.com/maps?saddr=My+Location&daddr=31+PPP+CARCARE+Yan+Wirot+Road,+Chanthanimit,+อ.เมือง+Chanthaburi'
            )
          }
          position={located}
        />
      </GoogleMap>
    ))
  )
);
