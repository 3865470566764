import { Modal } from 'antd';
import i18n from 'infrastructure/interfaces/adapters/i18n';

export const modalGoBack = (link: string): any => {
  Modal.error({
    title: i18n.t('error.modal.goBack.title'),
    content: i18n.t('error.modal.goBack.content'),
    onOk: () => {
      window.location.assign(link);
    },
    okText: i18n.t('error.modal.goBack.button'),
  });
};
