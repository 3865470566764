import {
  StatisticServicedInDaily,
  StatisticServicedInMonthly,
  StatisticServicedByCarBrand,
} from 'domain/entities';
import { DispatchState } from 'infrastructure/interfaces/adapters/store/rematch';
import StatisticRepository from 'infrastructure/repositories/statistic';
import HttpClient from 'infrastructure/interfaces/adapters/http_client';

export interface IStatisticStore {
  data: {
    servicedInDaily: StatisticServicedInDaily[];
    servicedInMonthly: StatisticServicedInMonthly[];
    servicedByCarBrand: StatisticServicedByCarBrand[];
  };
}

const stateInitial: IStatisticStore = {
  data: {
    servicedInDaily: [],
    servicedInMonthly: [],
    servicedByCarBrand: [],
  },
};
export default function statisticModel(httpClient: HttpClient) {
  const statisticRepo = new StatisticRepository(httpClient);
  return {
    state: stateInitial,
    reducers: {
      setItem: (
        state: IStatisticStore,
        payload: IStatisticStore['data']
      ): IStatisticStore => ({
        data: {
          ...payload,
        },
      }),
    },
    effects: (dispatch: DispatchState) => ({
      async getItem() {
        try {
          const data = await statisticRepo.getAll();

          // set data
          dispatch({ type: 'statistic/setItem', payload: data });
        } finally { }
      },
    }),
  };
}
