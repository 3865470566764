import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Skeleton } from 'antd';
import theme from 'assets/theme.json';
import { RootState } from 'infrastructure/interfaces/adapters/store/rematch';
import { getDateFormat } from 'utils';
import { ResponsiveWrapper } from './style';
import { StatisticServicedInMonthly } from 'domain/entities';

function InMonthly() {
  // STORE
  const data: StatisticServicedInMonthly[] = useSelector(
    (state: RootState) => state.statistic.data.servicedInMonthly
  );
  const loading: boolean = useSelector(
    (state: RootState) => state.loading.effects.statistic.getItem
  );

  // i18N
  const { t } = useTranslation();

  // IN CASE LOADING
  if (loading) return <Skeleton active />;

  // VARIABLE
  const chartData = data.map((r) => ({
    name: r.month,
    value: r.total,
  }));

  return (
    <ResponsiveWrapper>
      <ResponsiveContainer>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            tickFormatter={(t) => getDateFormat(t, 'MMM')}
          />
          <YAxis tickFormatter={(t) => `฿ ${t.toLocaleString()}`} />
          <Tooltip
            formatter={(v: string) => `฿ ${v.toLocaleString()}`}
            labelFormatter={(v: string) => getDateFormat(v, 'MMMM YYYY')}
          />
          <Bar
            dataKey="value"
            fill={theme.primary}
            name={`${t('dashboard.chart.amount')}`}
          />
        </BarChart>
      </ResponsiveContainer>
    </ResponsiveWrapper>
  );
}

export default memo(InMonthly);
