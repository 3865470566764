import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Routing from 'routes';
import { Layout } from 'antd';
import { User } from 'domain/entities';
import {
  dispatch,
  RootState,
} from 'infrastructure/interfaces/adapters/store/rematch';
import { Header, Loading } from 'presentation/components';
import SignIn from 'presentation/pages/signIn';

function AuthLoading() {
  // STORE
  const loading: boolean = useSelector((state: RootState) => state.loading.models.auth);

  if (loading === false) return null;

  return (
    <Loading />
  );
}


function App() {
  // STORE
  const user: User | null = useSelector((state: RootState) => state.auth.user);

  // VARIABLE
  let content = <SignIn />;
  if (user)
    content = (
      <Layout className="layout">
        <Header />
        <Layout.Content>
          <Routing />
        </Layout.Content>
        <Layout.Footer>&nbsp;</Layout.Footer>
      </Layout>
    );

  // EFFECT
  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch.auth.getOverView();
    }
  }, []);

  return (
    <>
      <AuthLoading />
      {content}
    </>
  );
}

export default memo(App);
