import { omit } from 'lodash';
import { CustomerCar } from './customer_car';
import { CustomerService } from './customer_service';

// TODO: Refactor here
export interface ICustomerListServices {
  free: number;
  last: Date;
  paid: number;
  unpaid: number;
}

export class BaseCustomer {
  readonly id: string;
  readonly code?: string;
  public title?: string;
  public email?: string;
  public address?: string;
  public phones?: string[];
  constructor(args?: Partial<BaseCustomer>) {
    this.id = args?.id || '';
    this.code = args?.code;
    this.title = args?.title;
    this.email = args?.email;
    this.address = args?.address;
    this.phones = args?.phones;
  }

  getLongTitle(): string {
    return `${this.code}${this.title && ` - ${this.title}`}`;
  }

  toAPIData(): any {
    return omit(this, ['id']);
  }
}
export class CustomerList extends BaseCustomer {
  public car_title?: string[];
  public service_summarize?: ICustomerListServices

  constructor(args?: Partial<CustomerList>) {
    super(args);
    this.car_title = args?.car_title;
    this.service_summarize = args?.service_summarize;
  }
}
export class CustomerRow extends BaseCustomer {
  public cars?: CustomerCar[];
  public services?: CustomerService[];
  constructor(args?: Partial<CustomerRow>) {
    super(args);
    this.cars = args?.cars;
    this.services = args?.services;
  }
}
