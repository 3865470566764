import { memo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumb } from 'antd';
import { BreadcrumbStyled } from './style';

export interface IBreadcrumb {
  title: string;
  link?: string;
}

export interface BreadcrumbPropTypes {
  data: IBreadcrumb[];
}

export const Breadcrumbs = memo(({ data }: BreadcrumbPropTypes) => {
  // i18N
  const { t } = useTranslation();

  return (
    <BreadcrumbStyled>
      <Breadcrumb.Item>
        <Link to="/">{t('home')}</Link>
      </Breadcrumb.Item>
      {data &&
        data.map((r, i) => (
          <Breadcrumb.Item key={i}>
            {r.link && <Link to={r.link}>{r.title}</Link>}
            {!r.link && r.title}
          </Breadcrumb.Item>
        ))}
    </BreadcrumbStyled>
  );
});
