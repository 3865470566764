import { omit } from 'lodash';
import { CarBrand } from './car_brand';

export class CustomerCar {
  readonly id?: string;
  readonly customerID?: string;
  public brandID?: string;
  public color?: string;
  public model?: string;
  public licensePlate?: string;
  public brandTitle?: string;
  constructor(args?: Partial<CustomerCar>) {
    this.id = args?.id;
    this.customerID = args?.customerID;
    this.brandID = args?.brandID;
    this.color = args?.color;
    this.model = args?.model;
    this.licensePlate = args?.licensePlate;
  }

  toAPIData(): any {
    return omit(this, ['id', 'customerID']);
  }

  setBrandTitle(brands: CarBrand[]) {
    if (this.brandID) {
      const found = brands.find((r) => r.id === this.brandID);
      if (found) {
        this.brandTitle = found.title;
      }
    }
  }

  searchByText(txt: string): boolean {
    return !!(
      this.licensePlate?.toLowerCase().includes(txt) ||
      this.model?.toLowerCase().includes(txt) ||
      this.color?.toLowerCase().includes(txt) ||
      this.brandTitle?.toLowerCase().includes(txt)
    );
  }
}
