import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { CarBrand, CustomerCar, CustomerRow } from 'domain/entities';
import {
  dispatch,
  RootState,
} from 'infrastructure/interfaces/adapters/store/rematch';
import { BaseListInput } from 'infrastructure/repositories/base';
import { ButtonLink, DataTable, LoadingWrapper } from 'presentation/components';
import FormDetail from './Form';

function Listing() {
  // STORE
  const deleting: boolean = useSelector(
    (state: RootState) => state.loading.effects.customer.carDelete
  )
  const customer: CustomerRow | null = useSelector(
    (state: RootState) => state.customer.row
  );
  const carBrands: CarBrand[] | null = useSelector(
    (state: RootState) => state.carBrand
  );
  const list: CustomerCar[] | undefined = useSelector(
    (state: RootState) => state.customer.row?.cars
  );

  // STATE
  const [rowEditing, handleRowEditing] = useState<CustomerCar | null>(
    null
  );
  const [search, handleSearch] = useState('');

  // i18N
  const { t } = useTranslation();

  // METHOD
  const handleChange = ({ search }: BaseListInput) => {
    handleSearch(search || '');
  };
  const confirmDelete = (row: CustomerCar) => {
    Modal.confirm({
      title: t('customer.car.listing.modal.delete.title', {
        licensePlate: row.licensePlate,
      }),
      okText: t('customer.car.listing.modal.delete.button.ok'),
      cancelText: t('customer.car.listing.modal.delete.button.cancel'),
      onOk() {
        dispatch.customer.carDelete({
          id: row.id as string,
          customerID: row.customerID as string,
        });
      },
    });
  };

  // IN CASE EDIT MODE
  if (rowEditing)
    return (
      <FormDetail
        data={rowEditing}
        onCancelEditor={() => handleRowEditing(null)}
      />
    );

  // VARIABLE
  const columns: ColumnsType<CustomerCar> = [
    {
      key: 'title',
      render: (val: string, row) => (
        <ButtonLink onClick={() => handleRowEditing(row)}>
          {row.licensePlate}
        </ButtonLink>
      ),
      title: t('customer.car.listing.columns.licensePlate'),
    },
    {
      dataIndex: 'brandTitle',
      key: 'brandTitle',
      title: t('customer.car.listing.columns.brand'),
    },
    {
      dataIndex: 'model',
      key: 'model',
      title: t('customer.car.listing.columns.model'),
    },
    {
      dataIndex: 'color',
      key: 'color',
      title: t('customer.car.listing.columns.color'),
    },
    {
      key: 'action',
      render: (val: string, row) => (
        <Button
          type="primary"
          danger
          shape="circle"
          icon={<DeleteOutlined />}
          onClick={() => confirmDelete(row)}
        />
      ),
      title: '',
    },
  ];
  const dataSource: CustomerCar[] | undefined = list?.map((r) => {
    r.setBrandTitle(carBrands as CarBrand[]);
    return r;
  })
    .filter((r) => {
      const searchText = search?.toLowerCase();
      if (!searchText) return r;
      if (r.searchByText(searchText)) return r;
      return null;
    });
  const ButtonCreate = (
    <Button
      type="primary"
      onClick={() =>
        handleRowEditing(new CustomerCar({ customerID: customer?.id }))
      }
    >
      <PlusOutlined />
      {t('customer.car.listing.button.create')}
    </Button>
  );

  return (
    <LoadingWrapper disabled={deleting}>
      <DataTable<CustomerCar>
        button={ButtonCreate}
        columns={columns}
        dataSource={dataSource as CustomerCar[]}
        name="customer-cars"
        onChange={handleChange}
        rowKey="id"
      />
    </LoadingWrapper>
  );
}

export default memo(Listing);
