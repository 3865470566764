import { memo } from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import { LoadingStyled } from './style';

export const Loading = memo(() => {
  return (
    <LoadingStyled>
      <Spin
        size="large"
        indicator={<LoadingOutlined style={{ fontSize: '10vh' }} spin />}
      />
    </LoadingStyled>
  );
});

type LoadingWrapperProps = {
  disabled: boolean
}

export const LoadingWrapper = styled.div<LoadingWrapperProps>`
  position: relative;
  &:before{
    ${({ disabled }) => (disabled && `
      content: ' ';
      position absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 1;
      cursor: wait;
    `)}
  }
`;
