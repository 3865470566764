import { BaseCustomer, CustomerCar, CustomerList, CustomerRow, CustomerService, Pagination } from 'domain/entities';
import CustomerRepository from 'infrastructure/repositories/customer';
import HttpClient from 'infrastructure/interfaces/adapters/http_client';
import { BaseListInput } from 'infrastructure/repositories/base';
import CustomerCarRepository from 'infrastructure/repositories/customer_car';
import CustomerServiceRepository from 'infrastructure/repositories/customer_service';
import { DispatchState } from '.';

export interface ICustomerStore {
  list: {
    data: CustomerList[];
    filter: {
      search: string;
    };
    pagination: Pagination;
  };
  row: CustomerRow | null;
}

const stateInitial: ICustomerStore = {
  list: {
    data: [],
    filter: {
      search: '',
    },
    pagination: new Pagination({
      limit: 10,
      start: 0,
      total: 0,
    }),
  },
  row: null,
};

export default function customerModel(httpClient: HttpClient) {
  const customerRepo = new CustomerRepository(httpClient);
  const customerCarRepo = new CustomerCarRepository(httpClient);
  const customerServiceRepo = new CustomerServiceRepository(httpClient);
  return {
    state: stateInitial,
    reducers: {
      setLoadingItem: (
        state: ICustomerStore,
        payload: boolean
      ): ICustomerStore => ({
        ...state,
      }),
      setListItem: (
        state: ICustomerStore,
        payload: ICustomerStore['list']
      ): ICustomerStore => ({
        ...state,
        list: payload,
      }),
      setRowItem: (
        state: ICustomerStore,
        payload: ICustomerStore['row']
      ): ICustomerStore => {
        return {
          ...state,
          // list: stateInitial.list,
          row: payload,
        };
      },
      unsetListItem: (state: ICustomerStore): ICustomerStore => ({
        ...state,
        list: stateInitial.list,
      }),
      unsetRowItem: (state: ICustomerStore): ICustomerStore => ({
        ...state,
        row: stateInitial.row,
      }),
    },
    effects: (dispatch: DispatchState) => ({
      async getList(params: BaseListInput) {
        try {
          const data = await customerRepo.getList(params);

          dispatch({
            type: 'customer/setListItem',
            payload: data,
          });
        } finally { }
      },
      async getRow(id: string) {
        dispatch({ type: 'customer/setItem', payload: true });
        try {
          const data = await customerRepo.getRow(id);
          dispatch({
            type: 'customer/setRowItem',
            payload: data,
          });
          return true;
        } catch {
          return false;
        } finally { }
      },
      async create(params: BaseCustomer) {
        dispatch({ type: 'customer/setItem', payload: true });
        try {
          const data = await customerRepo.create(params);
          dispatch({
            type: 'customer/setRowItem',
            payload: new CustomerRow({ ...data, cars: [], services: [] }),
          });
          dispatch({ type: 'customer/unsetListItem' });
          return true;
        } catch {
          return false;
        } finally { }
      },
      async delete(id: string) {
        dispatch({ type: 'customer/setItem', payload: true });
        try {
          await customerRepo.delete(id);
          dispatch({ type: 'customer/unsetListItem' });
          return true;
        } catch {
          return false;
        } finally { }
      },
      async update(
        payload: { id: string; params: BaseCustomer },
        { customer }: { customer: ICustomerStore }
      ) {
        dispatch({ type: 'customer/setItem', payload: true });
        try {
          const data = await customerRepo.update(payload.id, payload.params);
          dispatch({
            type: 'customer/setRowItem',
            payload: new CustomerRow({
              ...data,
              cars: customer.row?.cars,
              services: customer.row?.services,
            }),
          });
          dispatch({ type: 'customer/unsetListItem' });
          return true;
        } catch {
          return false;
        } finally { }
      },
      async carCreate(
        params: CustomerCar,
        { customer }: { customer: ICustomerStore }
      ) {
        dispatch({ type: 'customer/setItem', payload: true });
        try {
          const data = await customerCarRepo.create(params);

          // PUSH ROW
          const cars = customer.row?.cars as CustomerCar[];
          cars.push(data);

          dispatch({
            type: 'customer/setRowItem',
            payload: new CustomerRow({
              ...customer.row,
              cars,
            }),
          });
          return true;
        } catch {
          return false;
        } finally { }
      },
      async carDelete(
        payload: { customerID: string; id: string },
        { customer }: { customer: ICustomerStore }
      ) {
        dispatch({ type: 'customer/setItem', payload: true });
        try {
          await customerCarRepo.delete(payload.id, payload.customerID);

          // DELETE ROW
          let cars = customer.row?.cars as CustomerCar[];
          cars = cars.filter((r) => r.id !== payload.id);

          dispatch({
            type: 'customer/setRowItem',
            payload: new CustomerRow({
              ...customer.row,
              cars,
            }),
          });
          return true;
        } catch {
          return false;
        } finally { }
      },
      async carUpdate(
        payload: { id: string; params: CustomerCar },
        { customer }: { customer: ICustomerStore }
      ) {
        dispatch({ type: 'customer/setItem', payload: true });
        try {
          const data = await customerCarRepo.update(payload.id, payload.params);

          // REPLACE ROW
          let cars = customer.row?.cars as CustomerCar[];
          cars = cars.map((r) => {
            if (r.id === data.id) {
              return data;
            }
            return r;
          });

          dispatch({
            type: 'customer/setRowItem',
            payload: new CustomerRow({
              ...customer.row,
              cars,
            }),
          });
          return true;
        } catch {
          return false;
        } finally { }
      },
      async serviceCreate(
        params: CustomerService,
        { customer }: { customer: ICustomerStore }
      ) {
        dispatch({ type: 'customer/setItem', payload: true });
        try {
          const data = await customerServiceRepo.create(params);

          // PUSH ROW
          const services = customer.row?.services as CustomerService[];
          services.push(data);

          dispatch({
            type: 'customer/setRowItem',
            payload: new CustomerRow({
              ...customer.row,
              services,
            }),
          });
          return true;
        } catch {
          return false;
        } finally { }
      },
      async serviceUpdate(
        payload: { id: string; params: CustomerService },
        { customer }: { customer: ICustomerStore }
      ) {
        dispatch({ type: 'customer/setItem', payload: true });
        try {
          const data = await customerServiceRepo.update(
            payload.id,
            payload.params
          );

          // REPLACE ROW
          let services = customer.row?.services as CustomerService[];
          services = services.map((r) => {
            if (r.id === data.id) {
              return data;
            }
            return r;
          });

          dispatch({
            type: 'customer/setRowItem',
            payload: new CustomerRow({
              ...customer.row,
              services,
            }),
          });
          return true;
        } catch {
          return false;
        } finally { }
      },
      async serviceDelete(
        payload: { id: string; customerID: string },
        { customer }: { customer: ICustomerStore }
      ) {
        dispatch({ type: 'customer/setItem', payload: true });
        try {
          await customerServiceRepo.delete(payload.id, payload.customerID);

          // DELETE ROW
          let services = customer.row?.services as CustomerService[];
          services = services.filter((r) => r.id !== payload.id);

          dispatch({
            type: 'customer/setRowItem',
            payload: new CustomerRow({
              ...customer.row,
              services,
            }),
          });
          return true;
        } catch {
          return false;
        } finally { }
      },
    }),
  };
}
