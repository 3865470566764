import styled from 'styled-components';
import theme from 'assets/theme.json';

export const ButtonLink = styled.button`
  color: ${theme.primary};
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  text-align: left;
`;
