import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Modal, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { CustomerCar, CustomerRow, CustomerService } from 'domain/entities';
import {
  dispatch,
  RootState,
} from 'infrastructure/interfaces/adapters/store/rematch';
import { BaseListInput } from 'infrastructure/repositories/base';
import { ButtonLink, DataTable, ListUnstyled, LoadingWrapper } from 'presentation/components';
import { getDateFormat } from 'utils';
import FormDetail from './Form';

function Listing() {
  // STORE
  const deleting: boolean = useSelector(
    (state: RootState) => state.loading.effects.customer.serviceDelete
  )
  const cars: CustomerCar[] | undefined = useSelector(
    (state: RootState) => state.customer.row?.cars
  );
  const customer: CustomerRow | null = useSelector(
    (state: RootState) => state.customer.row
  );
  const list: CustomerService[] | undefined = useSelector(
    (state: RootState) => state.customer.row?.services
  );

  // STATE
  const [rowEditing, handleRowEditing] = useState<CustomerService | null>(
    null
  );
  const [search, handleSearch] = useState('');

  // i18N
  const { t } = useTranslation();

  // METHOD
  const handleChange = ({ search }: BaseListInput) => {
    handleSearch(search || '');
  };
  const confirmDelete = (row: CustomerService) => {
    Modal.confirm({
      title: t('customer.service.listing.modal.delete.title', {
        title: getDateFormat(row.dateTime),
      }),
      okText: t('customer.service.listing.modal.delete.button.ok'),
      cancelText: t('customer.service.listing.modal.delete.button.cancel'),
      onOk() {
        dispatch.customer.serviceDelete({
          id: row.id as string,
          customerID: row.customerID as string,
        });
      },
    });
  };

  // IN CASE EDIT
  if (rowEditing)
    return (
      <FormDetail
        data={rowEditing}
        onCancelEditor={() => handleRowEditing(null)}
      />
    );

  // VARIABLE
  const columns: ColumnsType<CustomerService> = [
    {
      key: 'titleNDetail',
      render: (val: string, row) => (
        <ListUnstyled>
          <li>
            <ButtonLink onClick={() => handleRowEditing(row)}>
              {row.dateTime?.toLocaleString()}
            </ButtonLink>
          </li>
          <li>
            {t('customer.service.listing.dataSource.titleNDetail.branch')}:{' '}
            {t(`common.service.branch.${row.branch}`)}
          </li>
          <li>
            <Badge
              status={row.getStatusColor()}
              text={t(
                `customer.service.listing.dataSource.titleNDetail.status.${row.getStatusTitle()}`
              )}
            />
          </li>
          {row.description && (
            <li>
              <i>{row.description}</i>
            </li>
          )}
        </ListUnstyled>
      ),
      title: '',
    },
    {
      dataIndex: 'carTitle',
      key: 'car',
      render: (val: string) =>
        val ? (
          <strong>{val}</strong>
        ) : (
          <i>{t('customer.service.listing.dataSource.car.notFound')}</i>
        ),
      title: (
        <>
          <span role="img" aria-label="car">
            &#128664;
          </span>{' '}
          {t('customer.service.listing.columns.car')}
        </>
      ),
    },
    {
      align: 'right',
      dataIndex: 'priceTotal',
      key: 'amount',
      render: (val: number) => (
        <Tag color="purple">{`฿ ${val.toLocaleString()}`}</Tag>
      ),
      title: (
        <>
          <span role="img" aria-label="money_flying">
            &#x1F4B8;
          </span>{' '}
          {t('customer.service.listing.columns.amount')}
        </>
      ),
    },
    {
      key: 'action',
      render: (val: string, row) => (
        <Button
          type="primary"
          danger
          shape="circle"
          icon={<DeleteOutlined />}
          onClick={() => confirmDelete(row)}
        />
      ),
      title: '',
    },
  ];
  const dataSource = list?.map((r) => {
    r.setCarTitle(cars as CustomerCar[]);
    return r;
  })
    .filter((r) => {
      if (!search) return r;
      if (r.searchByText(search)) return r;
      return null;
    });
  const ButtonCreate = (
    <Button
      type="primary"
      onClick={() =>
        handleRowEditing(
          new CustomerService({
            customerID: customer?.id,
            branch: 1,
            detail: [],
          })
        )
      }
    >
      <PlusOutlined />
      {t('customer.service.listing.button.create')}
    </Button>
  );

  return (
    <LoadingWrapper disabled={deleting}>
      <DataTable<CustomerService>
        button={ButtonCreate}
        columns={columns}
        dataSource={dataSource as CustomerService[]}
        name="customer-services"
        onChange={handleChange}
        rowKey="id"
      />
    </LoadingWrapper>
  );
}

export default memo(Listing);
