import { CarBrand } from 'domain/entities';

const stateInitial: CarBrand[] | null = null;

export default function carBrandModel() {
  return {
    state: stateInitial,
    reducers: {
      setItem: (state: CarBrand, payload: CarBrand): CarBrand => payload,
    },
  };
}
