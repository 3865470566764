import styled from 'styled-components';

export const MapWrapper = styled.div`
  height: 100vh;
`;

export const Loading = styled.div``;

export const Container = styled.div`
  height: 100%;
`;

export const MapEle = styled.div`
  height: 100%;
`;
