import {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Input, Row, Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { Pagination } from 'domain/entities';
import { DataTableStyled } from './style';
import { BaseListInput } from 'infrastructure/repositories/base';

interface DataTableFilterPropTypes {
  firstFetch: boolean;
  initialValues?: {
    search?: string;
  };
  loading?: boolean;
  name: string;
  onSubmit: (params: BaseListInput) => void;
}

interface IDataTableFilterImperativeHandle {
  getValue: () => IDataTableFilterValue;
}

interface DataTablePropTypes<T> {
  button: React.ReactNode;
  columns: ColumnsType<T>;
  dataSource: T[];
  filter?: {
    search?: string;
  };
  loading?: boolean;
  name: string;
  onChange: (params: BaseListInput) => void;
  pagination?: Pagination;
  rowKey?: string;
}
interface IDataTableFilterValue {
  search?: string;
}

const DataTableFilter = forwardRef<
  IDataTableFilterImperativeHandle,
  DataTableFilterPropTypes
>(
  (
    {
      firstFetch,
      initialValues,
      loading,
      name,
      onSubmit,
    }: DataTableFilterPropTypes,
    forwardedRef: Ref<IDataTableFilterImperativeHandle>
  ) => {
    // STATE
    const [filterValue, setFilterValue] = useState<IDataTableFilterValue>(
      initialValues as IDataTableFilterValue
    );
    // i18N
    const { t } = useTranslation();

    // VARIABLE
    const [form] = Form.useForm();

    useImperativeHandle(forwardedRef, () => ({
      getValue: () => filterValue,
    }));

    // METHOD
    const handleFilterSubmit = (v: IDataTableFilterValue) => {
      const data = { start: 0, ...v };
      onSubmit(data);
      setFilterValue(data);
    };
    const handleFilterSearch = () => {
      form.submit();
    };

    // EFFECT AS MOUNTED
    useEffect(() => {
      if (firstFetch) {
        form.submit();
      }
    }, [firstFetch, form]);

    return (
      <Form
        form={form}
        initialValues={initialValues}
        name={`${name}-dataTable-form`}
        onFinish={handleFilterSubmit}
      >
        <Form.Item name="search">
          <Input.Search
            autoComplete="off"
            disabled={loading ? true : false}
            onSearch={handleFilterSearch}
            placeholder={t('components.dataTable.search.placeholder')}
          />
        </Form.Item>
      </Form>
    );
  }
);

export function DataTable<DataSourceType extends object>({
  button,
  columns,
  dataSource,
  filter,
  loading,
  name,
  onChange,
  pagination,
  rowKey,
}: DataTablePropTypes<DataSourceType>) {
  // REF
  const filterRef = useRef<IDataTableFilterImperativeHandle>(null);

  // METHOD
  const handleRefetch = (params: BaseListInput) => {
    onChange({ ...pagination, ...params });
  };
  const handleTableChange = (params: TablePaginationConfig) => {
    const paging = new Pagination({
      limit: params?.pageSize || 0,
      total: params?.total || 0,
      current: params?.current,
      start: 0,
    });

    handleRefetch({
      search: filter?.search,
      start: paging.start,
      limit: paging.limit,
    });
  };

  // PAGINATION
  let tablePagination: TablePaginationConfig | undefined = undefined;
  if (pagination) {
    tablePagination = {
      current: pagination.getCurrentPage(),
      pageSize: pagination?.limit,
      total: pagination?.total,
    };
  }

  return (
    <DataTableStyled>
      <Row gutter={16}>
        <Col xs={12}>{button}</Col>
        <Col xs={12} lg={{ span: 8, offset: 4 }}>
          <DataTableFilter
            ref={filterRef}
            firstFetch={dataSource.length === 0}
            initialValues={filter}
            loading={loading}
            name={name}
            onSubmit={handleRefetch}
          />
        </Col>
      </Row>
      <Table<DataSourceType>
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        onChange={handleTableChange}
        pagination={tablePagination}
        rowKey={rowKey || 'key'}
      />
    </DataTableStyled>
  );
}
