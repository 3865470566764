import { UserOutlined } from '@ant-design/icons';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, DispatchState } from 'infrastructure/interfaces/adapters/store/rematch';
import { FlagStyle, HeaderStyled, MenuStyled } from './style';
import flagTH from 'assets/images/lang/th.png';
import flagEN from 'assets/images/lang/en.png';
import logo from 'assets/images/logo.png';

const Menus = memo(() => {
  // STORE
  const authTitle: string | undefined = useSelector(
    (state: RootState) => state.auth.user?.title
  );
  const dispatch = useDispatch<DispatchState>();

  // i18N
  const { t, i18n } = useTranslation();

  // variable
  const langs = [
    {
      flag: flagEN,
      title: 'English',
      value: 'en',
    },
    {
      flag: flagTH,
      title: 'ภาษาไทย',
      value: 'th',
    },
  ];

  let langCur = langs.find((r) => r.value === i18n.language);

  if (!langCur) {
    langCur = langs[0];
  }

  return (
    <MenuStyled mode="horizontal">
      <Menu.SubMenu
        className="onlyIcon"
        title={
          <FlagStyle
            className="flag"
            src={get(langCur, 'flag')}
            alt={get(langCur, 'title')}
          />
        }
      >
        {langs.map((r) => (
          <Menu.Item
            key={`lang-${r.value}`}
            onClick={() => i18n.changeLanguage(r.value)}
            disabled={r.value === i18n.language}
          >
            <FlagStyle className="flag" src={r.flag} alt={r.title} />
            {r.title}
          </Menu.Item>
        ))}
      </Menu.SubMenu>
      <Menu.SubMenu
        title={
          <span className="submenu-title-wrapper">
            <UserOutlined />
            <span className="submenu-title">{authTitle}</span>
          </span>
        }
      >
        <Menu.Item key="signout" onClick={dispatch.auth.signOut}>
          {t('signOut')}
        </Menu.Item>
      </Menu.SubMenu>
    </MenuStyled>
  );
});

export const Header = memo(() => {
  return (
    <HeaderStyled>
      <Link to="/" className="logo">
        <img src={logo} alt="PPPCARCARE" />
      </Link>
      <Menus />
    </HeaderStyled>
  );
});
