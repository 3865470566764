import { FieldError, Rule } from 'rc-field-form/lib/interface';
import i18n from 'infrastructure/interfaces/adapters/i18n';
import localeEN from 'antd/lib/date-picker/locale/en_US';
import localeTH from 'antd/lib/date-picker/locale/th_TH';

export const getLocale = (): any => {
  switch (i18n.language) {
    case 'th':
      return localeTH;
    default:
      return localeEN;
  }
};

export const hasErrors = (val: FieldError[]) => {
  return !!val.filter(({ errors }: { errors: string[] }) => errors.length)
    .length;
};

export const numberFormat = (val: string | number | undefined): string => {
  if (!val) return '';
  return `฿ ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const rules = (rule: string, options?: any): Rule => {
  switch (rule) {
    case 'hasLen':
      return {
        len: options,
        message: i18n.t('error.form.rules.hasLen', { len: options }),
      };
    case 'patternPhoneNumber':
      return {
        pattern: /^[0]([0-9]{9})/g,
        message: i18n.t('error.form.rules.patternPhoneNumber'),
      };
    case 'required':
      return {
        required: true,
        message: i18n.t('error.form.rules.required', { field: options }),
      };
    case 'requiredWithOutWhitespace':
      return {
        required: true,
        message: i18n.t('error.form.rules.required', { field: options }),
        whitespace: true,
      };
    case 'typeEmail':
      return {
        type: 'email',
        message: i18n.t('error.form.rules.typeEmail'),
      };
    default:
      return {};
  }
};
