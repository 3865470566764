import { CarBrand, ResponseIncluded, User, UserCredential } from 'domain/entities';
import { CarBrandModel, UserModel } from 'infrastructure/models';
import BaseRepository from './base';

export default class AuthRepository extends BaseRepository {
  async getOverView() {
    const res = await this.httpClient.request<
      ResponseIncluded<UserModel, { carBrands: CarBrandModel[] }>
    >({
      url: '/auth/overview',
      method: 'GET',
    });

    const { data, included } = res.data;

    return {
      user: new User(data as User),
      carBrands: included.carBrands.map(
        (r: any) => new CarBrand({ id: r.id, title: r.title })
      ),
    };
  }

  async signIn(params: UserCredential) {
    return await this.httpClient.request({
      url: '/signin',
      method: 'POST',
      data: { ...params },
    });
  }

  async signOut() {
    return await this.httpClient.request({
      url: '/signout',
      method: 'POST',
    });
  }
}
