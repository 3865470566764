import { BadgeProps } from 'antd/lib/badge';
import { omit } from 'lodash';
import moment from 'moment';
import { getDateFormat } from 'utils';
import { CustomerCar } from './customer_car';

export class CustomerService {
  readonly id?: string;
  public customerID?: string;
  public carID?: string;
  public branch?: number;
  public dateTime?: Date;
  public description?: string;
  public detail?: string[];
  public isPay?: boolean;
  public priceTotal?: number;
  public carTitle?: string;
  constructor(args?: Partial<CustomerService>) {
    this.id = args?.id;
    this.customerID = args?.customerID;
    this.carID = args?.carID;
    this.branch = args?.branch;
    this.dateTime = args?.dateTime ? new Date(args?.dateTime) : undefined;
    this.description = args?.description;
    this.detail = args?.detail;
    this.isPay = args?.isPay;
    this.priceTotal = args?.priceTotal;
  }

  toAPIData(): any {
    return omit(this, ['id', 'customerID']);
  }

  toFormValue(): any {
    return {
      ...this,
      dateTime: this.dateTime ? moment(this?.dateTime) : undefined,
    };
  }

  getTitle(): string {
    if (!this.dateTime) return '';
    return getDateFormat(this.dateTime);
  }

  setCarTitle(cars: CustomerCar[]) {
    if (this.carID) {
      const found = cars.find((r) => r.id === this.carID);
      if (found) {
        this.carTitle = found.licensePlate;
      }
    }
  }

  getStatusColor(): BadgeProps['status'] {
    if (this.priceTotal === 0) return 'processing';
    else if (this.isPay) return 'success';
    else return 'error';
  }

  getStatusTitle(): string {
    if (this.priceTotal === 0) return 'free';
    else if (this.isPay) return 'paid';
    else return 'unpaid';
  }

  searchByText(txt: string): boolean {
    return (
      this.description?.includes(txt) ||
      this.dateTime?.toLocaleString().includes(txt) ||
      (this.carTitle && this.carTitle.toString().includes(txt)) ||
      (this.priceTotal || 0).toString().includes(txt)
    );
  }
}
