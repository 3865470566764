import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enCommon from 'assets/locales/en/common.json';
import enComponents from 'assets/locales/en/components.json';
import enCustomer from 'assets/locales/en/customer.json';
import enDashboard from 'assets/locales/en/dashboard.json';
import enError from 'assets/locales/en/error.json';
import enSignIn from 'assets/locales/en/signIn.json';

import thCommon from 'assets/locales/th/common.json';
import thComponents from 'assets/locales/th/components.json';
import thCustomer from 'assets/locales/th/customer.json';
import thDashboard from 'assets/locales/th/dashboard.json';
import thError from 'assets/locales/th/error.json';
import thSignIn from 'assets/locales/th/signIn.json';

const en = {
  translation: {
    common: enCommon,
    components: enComponents,
    customer: enCustomer,
    home: 'Home',
    dashboard: enDashboard,
    error: enError,
    signIn: enSignIn,
    signOut: 'Sign Out',
  },
};

const th = {
  translation: {
    common: thCommon,
    components: thComponents,
    customer: thCustomer,
    home: 'หน้าแรก',
    dashboard: thDashboard,
    error: thError,
    signIn: thSignIn,
    signOut: 'ออกจากระบบ',
  },
};

const resources = { en, th };

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'th',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
