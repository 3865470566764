import { Breadcrumb, Layout, Menu } from 'antd';
import styled from 'styled-components';
import theme from 'assets/theme.json';

export const BreadcrumbStyled = styled(Breadcrumb)`
  && {
    margin-bottom: 1rem;
  }
`;

export const FlagStyle = styled.img`
  height: 1rem;
  margin-right: 5px;
`;

export const DataTableStyled = styled.div`
  & .ant-table-wrapper {
    margin-top: 10px;
  }
`;

export const HeaderStyled = styled(Layout.Header)`
  && {
    position: fixed;
    width: 100%;
    padding: 0 1rem;
    z-index: 2;
    background: ${theme.primary};
    & .logo {
      display: inline-block;
      height: 100%;
      & img {
        max-height: 1.5rem;
      }
    }
    & .ant-menu {
      line-height: 62px;
      background: transparent;
    }
  }
`;

export const LoadingStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.45);
  cursor: progress;
  & .ant-spin {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const MenuStyled = styled(Menu)`
  && {
    float: right;
    border: none;
    color: rgba(255, 255, 255, 0.65);
    & .ant-menu-submenu {
      border: none !important;
      &:hover,
      &.ant-menu-submenu-active,
      &.ant-menu-submenu-open,
      & .ant-menu-submenu-title:hover {
        color: white !important;
      }
      &.onlyIcon {
        margin: 0;
        padding: 0;
      }
      & .ant-menu-submenu-title {
        padding: 0 10px;
        & .anticon {
          @media (max-width: < 576px) {
            margin-right: 0;
          }
        }
        & .submenu-title {
          @media (max-width: < 576px) {
            display: none;
          }
        }
      }
      & ${FlagStyle} {
        margin-right: 0;
      }
    }
  }
`;