import { Fragment, memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { includes } from 'lodash';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Switch,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import {
  dispatch,
  RootState,
} from 'infrastructure/interfaces/adapters/store/rematch';
import { CarBrand, CustomerCar, CustomerService, NewServices } from 'domain/entities';
import { formHorizontal, LoadingWrapper } from 'presentation/components';
import { getLocale, hasErrors, numberFormat, rules } from 'utils';
import { TableServices } from './style';

export interface CustomerServiceDetailForm {
  onCancelEditor: any;
  data: CustomerService;
}

function FormService({ onCancelEditor, data }: CustomerServiceDetailForm) {
  // STORE
  const submitting: boolean = useSelector(
    (state: RootState) => state.loading.effects.customer.serviceCreate || state.loading.effects.customer.serviceUpdate
  )
  const carBrands: CarBrand[] | null = useSelector(
    (state: RootState) => state.carBrand
  );
  const cars: CustomerCar[] | undefined = useSelector(
    (state: RootState) => state.customer.row?.cars
  );

  // i18N
  const { t } = useTranslation();

  // VARIABLE
  const [form] = Form.useForm();
  const services = NewServices();

  // METHOD
  const getPriceTotal = (detail: string[]): number => {
    const servicePrices = services.reduce(
      (acc: any, cur: any) => [...acc, ...cur.children],
      []
    );

    return detail.reduce((acc: number, cur: string) => {
      const key = cur.split('-');
      const service = servicePrices.find(
        (r) => r.code === `${key[0]}-${key[1]}`
      );

      if (service) return acc + service[key[2]];

      return acc;
    }, 0);
  };
  const handleDetailChange = async (field: string, val: boolean) => {
    let alts = form.getFieldValue('detail');
    if (val) {
      alts.push(field);
    } else {
      alts = alts?.filter((v: string) => v !== field);
    }

    form.setFieldsValue({
      detail: alts,
      priceTotal: getPriceTotal(alts),
    });
  };
  const handleSubmit = async (val: any) => {
    const upsert = new CustomerService({
      ...val,
      customerID: data?.customerID,
      dateTime: val.dateTime.format(),
    });

    let res;
    if (data?.id) {
      res = await dispatch.customer.serviceUpdate({
        id: data?.id,
        params: upsert,
      });
    } else {
      res = await dispatch.customer.serviceCreate(upsert);
    }
    if (res) onCancelEditor();
  };

  //
  const customerCars = cars?.map((r) => {
    r.setBrandTitle(carBrands as CarBrand[]);
    return r;
  });

  return (
    <LoadingWrapper disabled={submitting}>
      <Form
        {...formHorizontal.input}
        form={form}
        initialValues={data.toFormValue()}
        name="customer-service"
        onFinish={handleSubmit}
      >
        <Form.Item
          label={t('customer.service.detail.form.label.carID')}
          name="carID"
          rules={[
            rules('required', t('customer.service.detail.form.label.carID')),
          ]}
        >
          <Select
            placeholder={t('customer.service.detail.form.placeholder.carID')}
          >
            {customerCars?.map((r: any) => (
              <Select.Option key={r.id} value={r.id}>
                {`${r.brandTitle} ${r.model && r.model} - ${r.licensePlate} ${r.color && `, ${r.color}`
                  }`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item noStyle name="detail">
          <Input type="hidden" />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <Form.Item wrapperCol={{ span: 24 }}>
              <TableServices>
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>S+M</th>
                      <th>L</th>
                      <th>XL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {services.map((r) => (
                      <Fragment key={r.service}>
                        <tr>
                          <th colSpan={4}>
                            {t(
                              `customer.service.detail.form.table.th.${r.service}.title`
                            )}
                          </th>
                        </tr>
                        {
                          r.children?.map((r2) => (
                            <tr key={r2.code}>
                              <td>
                                {t(
                                  `customer.service.detail.form.table.th.${r.service}.${r2.code}`
                                )}
                              </td>
                              <td>
                                {!!r2.m && (
                                  <Form.Item valuePropName="checked">
                                    <Switch
                                      checked={includes(
                                        getFieldValue('detail'),
                                        `${r2.code}-m`
                                      )}
                                      checkedChildren={`฿ ${r2.m.toLocaleString()}`}
                                      unCheckedChildren={`฿ ${r2.m.toLocaleString()}`}
                                      onChange={(v) =>
                                        handleDetailChange(`${r2.code}-m`, v)
                                      }
                                    />
                                  </Form.Item>
                                )}
                              </td>
                              <td>
                                {!!r2.l && (
                                  <Form.Item valuePropName="checked">
                                    <Switch
                                      checked={includes(
                                        getFieldValue('detail'),
                                        `${r2.code}-l`
                                      )}
                                      checkedChildren={`฿ ${r2.l.toLocaleString()}`}
                                      unCheckedChildren={`฿ ${r2.l.toLocaleString()}`}
                                      onChange={(v) =>
                                        handleDetailChange(`${r2.code}-l`, v)
                                      }
                                    />
                                  </Form.Item>
                                )}
                              </td>
                              <td>
                                {!!r2.xl && (
                                  <Form.Item valuePropName="checked">
                                    <Switch
                                      checked={includes(
                                        getFieldValue('detail'),
                                        `${r2.code}-xl`
                                      )}
                                      checkedChildren={`฿ ${r2.xl.toLocaleString()}`}
                                      unCheckedChildren={`฿ ${r2.xl.toLocaleString()}`}
                                      onChange={(v) =>
                                        handleDetailChange(`${r2.code}-xl`, v)
                                      }
                                    />
                                  </Form.Item>
                                )}
                              </td>
                            </tr>
                          ))
                        }
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              </TableServices>
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item
          label={t('customer.service.detail.form.label.description')}
          name="description"
        >
          <Input.TextArea
            placeholder={t(
              'customer.service.detail.form.placeholder.description'
            )}
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item
          label={t('customer.service.detail.form.label.branch')}
          name="branch"
        >
          <Radio.Group>
            <Radio value={1}>{t('common.service.branch.1')}</Radio>
            <Radio value={2}>{t('common.service.branch.2')}</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={t('customer.service.detail.form.label.priceTotal')}
          name="priceTotal"
          rules={[
            rules('required', t('customer.service.detail.form.label.priceTotal')),
          ]}
        >
          <InputNumber
            formatter={numberFormat}
            min={0}
            autoComplete="off"
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          label={t('customer.service.detail.form.label.dateTime')}
          name="dateTime"
          rules={[
            rules('required', t('customer.service.detail.form.label.dateTime')),
          ]}
        >
          <DatePicker
            showTime
            format="HH:mm:ss DD/MM/YYYY"
            locale={getLocale()}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item label=" " colon={false} name="isPay">
          <Radio.Group>
            <Radio value={false}>
              {t('customer.service.detail.form.label.isPay.0')}
            </Radio>
            <Radio value={true}>
              {t('customer.service.detail.form.label.isPay.1')}
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item {...formHorizontal.button}>
          <Row gutter={16}>
            <Col xs={12}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldsError }) => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<SaveOutlined />}
                    block
                    disabled={hasErrors(getFieldsError())}
                  >
                    {t('customer.service.detail.form.button.submit')}
                  </Button>
                )}
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Button type="link" block onClick={() => onCancelEditor()}>
                {t('customer.service.detail.form.button.cancel')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </LoadingWrapper >
  );
}

export default memo(FormService);
