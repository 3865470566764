import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Card } from 'antd';
import { Breadcrumbs } from 'presentation/components';
import CustomerListing from './CustomerList';
import InDaily from './InDaily';
import InMonthly from './InMonthly';
import ByCarBrand from './ByCarBrand';

function Dashboard() {
  // i18N
  const { t } = useTranslation();

  // VARIABLE
  const layout = {
    xs: 24,
    lg: 12,
  };
  const layoutSub = {
    xs: 24,
  };

  return (
    <>
      <Breadcrumbs data={[{ title: t('dashboard.title') }]} />
      <Row gutter={16}>
        <Col {...layout}>
          <CustomerListing />
        </Col>
        <Col {...layout}>
          <Row gutter={16}>
            <Col {...layoutSub}>
              <Card title={t('dashboard.chart.servicedInDaily.title')}>
                <InDaily />
              </Card>
            </Col>
            <Col {...layoutSub}>
              <Card title={t('dashboard.chart.servicedInMonthly.title')}>
                <InMonthly />
              </Card>
            </Col>
            <Col {...layoutSub}>
              <Card title={t('dashboard.chart.servicedByCarBrand.title')}>
                <ByCarBrand />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default memo(Dashboard);
