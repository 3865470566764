import { CustomerCar, Response } from 'domain/entities';
import { CustomerCarModel } from 'infrastructure/models';
import BaseRepository from './base';

export default class CustomerCarRepository extends BaseRepository {
  async create(params: CustomerCar) {
    const res = await this.httpClient.request<Response<CustomerCarModel>>({
      url: `/customers/${params.customerID}/cars`,
      method: 'POST',
      data: params.toAPIData(),
    });

    const { data } = res.data;

    return new CustomerCar(data);
  }
  async delete(id: string, customerID: string) {
    await this.httpClient.request<Response<unknown>>({
      url: `/customers/${customerID}/cars/${id}`,
      method: 'DELETE',
    });
  }
  async update(id: string, params: CustomerCar) {
    const res = await this.httpClient.request<Response<CustomerCarModel>>({
      url: `/customers/${params.customerID}/cars/${id}`,
      method: 'PATCH',
      data: params.toAPIData(),
    });

    const { data } = res.data;

    return new CustomerCar(data);
  }
}
