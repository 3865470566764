import styled from 'styled-components';
import theme from 'assets/theme.json';

export const TableServices = styled.div`
  overflow-y: auto;
  & table {
    width: 100%;
    & thead {
      & tr {
        & th {
          text-align: center;
        }
      }
    }
    & tbody {
      & tr {
        &:hover {
          background: rgba(0, 0, 0, 0.1);
        }
        & th,
        td {
          padding: 5px 10px;
        }
        & th {
          color: ${theme.primary};
          border-bottom: 1px solid ${theme.primary};
        }
        & td {
          & .ant-switch {
            min-width: 76px;
            & .ant-switch-inner {
              text-align: right;
              word-break: break-all;
            }
          }
        }
      }
    }
  }
`;
