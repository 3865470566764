export class User {
  readonly id?: number;
  readonly role?: string;
  readonly title?: string;
  readonly username?: string;
  constructor(args: Partial<User>) {
    this.id = args.id;
    this.role = args.role;
    this.title = args.role;
    this.username = args.role;
  }
}

export class UserCredential {
  constructor(readonly username: number, readonly password: string) { }
}
