import { init, Models, RematchDispatch, RematchRootState } from '@rematch/core';
import loadingPlugin, { ExtraModelsFromLoading } from '@rematch/loading'
import { message } from 'antd';
import i18n from 'infrastructure/interfaces/adapters/i18n';
import httpClient from 'infrastructure/interfaces/adapters/http_client';
import authModel from './auth';
import carBrandModel from './car_brand';
import customerModel from './customer';
import statisticModel from './statistic';

const httpService = new httpClient(message, i18n);

export interface RootModel extends Models<RootModel> {
  auth: ReturnType<typeof authModel>
  carBrand: ReturnType<typeof carBrandModel>
  customer: ReturnType<typeof customerModel>
  statistic: ReturnType<typeof statisticModel>
}

type FullModel = ExtraModelsFromLoading<RootModel>

const store = init<RootModel, FullModel>({
  redux: {
    devtoolOptions: {
      disabled: !process.env.NODE_ENV || process.env.NODE_ENV === 'production',
    },
  },
  models: {
    auth: authModel(httpService),
    carBrand: carBrandModel(),
    customer: customerModel(httpService),
    statistic: statisticModel(httpService),
  },
  plugins: [loadingPlugin()]
});

export default store;
export const { dispatch } = store;
export type DispatchState = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel, FullModel>;
