import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { CarBrand, CustomerCar } from 'domain/entities';
import {
  dispatch,
  RootState,
} from 'infrastructure/interfaces/adapters/store/rematch';
import { formHorizontal, LoadingWrapper } from 'presentation/components';
import { hasErrors, rules } from 'utils';

interface CustomerCarForm {
  onCancelEditor: any;
  data?: CustomerCar;
}

function CarForm({ onCancelEditor, data }: CustomerCarForm) {
  // STORE
  const brands: CarBrand[] | null = useSelector((state: RootState) => state.carBrand);
  const submitting: boolean = useSelector(
    (state: RootState) => state.loading.effects.customer.carCreate || state.loading.effects.customer.carUpdate
  )

  // i18N
  const { t } = useTranslation();

  // VARIABLE
  const [form] = Form.useForm();

  // METHOD
  const handleSubmit = async (val: any) => {
    let res;
    const upsert = new CustomerCar({
      ...val,
      customerID: data?.customerID,
    });
    if (data?.id) {
      res = await dispatch.customer.carUpdate({ id: data.id, params: upsert });
    } else {
      res = await dispatch.customer.carCreate(upsert);
    }

    if (res) onCancelEditor();
  };

  return (
    <LoadingWrapper disabled={submitting}>
      <Form
        {...formHorizontal.input}
        form={form}
        initialValues={data}
        name="customerCar"
        onFinish={handleSubmit}
      >
        <Form.Item
          label={t('customer.car.detail.form.label.brandID')}
          name="brandID"
          rules={[rules('required', t('customer.car.detail.form.label.brandID'))]}
        >
          <Select placeholder={t('customer.car.detail.form.placeholder.brandID')}>
            {brands?.map((r) => (
              <Select.Option key={r.id} value={r.id || new Date().getMilliseconds()}>
                {r.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={t('customer.car.detail.form.label.model')} name="model">
          <Input placeholder="C300" autoComplete="off" />
        </Form.Item>
        <Form.Item
          label={t('customer.car.detail.form.label.licensePlate')}
          name="licensePlate"
          rules={[
            rules(
              'requiredWithOutWhitespace',
              t('customer.car.detail.form.label.licensePlate')
            ),
          ]}
        >
          <Input placeholder="กท 7 จันทบุรี" autoComplete="off" />
        </Form.Item>
        <Form.Item label={t('customer.car.detail.form.label.color')} name="color">
          <Input
            placeholder={t('customer.car.detail.form.placeholder.color')}
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item {...formHorizontal.button}>
          <Row gutter={16}>
            <Col xs={12}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldsError }) => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<SaveOutlined />}
                    block
                    disabled={hasErrors(getFieldsError())}
                  >
                    {t('customer.car.detail.form.button.submit')}
                  </Button>
                )}
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Button type="link" block onClick={() => onCancelEditor()}>
                {t('customer.car.detail.form.button.cancel')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </LoadingWrapper>
  );
}

export default memo(CarForm);
