import { CustomerService, Response } from 'domain/entities';
import { CustomerServiceModel } from 'infrastructure/models';
import BaseRepository from './base';

export default class CustomerServiceRepository extends BaseRepository {
  async create(params: CustomerService) {
    const res = await this.httpClient.request<Response<CustomerServiceModel>>({
      url: `/customers/${params.customerID}/services`,
      method: 'POST',
      data: params.toAPIData(),
    });

    const { data } = res.data;

    return new CustomerService(data as CustomerService);
  }
  async delete(id: string, customerID: string) {
    await this.httpClient.request<Response<unknown>>({
      url: `/customers/${customerID}/services/${id}`,
      method: 'DELETE',
    });
  }
  async update(id: string, params: CustomerService) {
    const res = await this.httpClient.request<Response<CustomerServiceModel>>({
      url: `/customers/${params.customerID}/services/${id}`,
      method: 'PATCH',
      data: params.toAPIData(),
    });

    const { data } = res.data;

    return new CustomerService(data as CustomerService);
  }
}
