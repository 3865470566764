import { Fragment, memo } from 'react';
import { withRouter, Link, RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Card, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { PlusOutlined } from '@ant-design/icons';
import { CustomerList, ICustomerListServices, Pagination } from 'domain/entities';
import {
  RootState,
  DispatchState,
} from 'infrastructure/interfaces/adapters/store/rematch';
import { BaseListInput } from 'infrastructure/repositories/base';
import { DataTable, ListUnstyled } from 'presentation/components';
import { getDateFormat } from 'utils';

function CustomerListing({ history }: RouteComponentProps) {
  // STORE
  const {
    data,
    filter,
    pagination,
  }: {
    data: CustomerList[];
    filter: { search: string };
    pagination: Pagination;
  } = useSelector((state: RootState) => state.customer.list);
  const loading: boolean = useSelector(
    (state: RootState) => state.loading.effects.customer.getList
  );

  const dispatch = useDispatch<DispatchState>();

  // i18N
  const { t } = useTranslation();

  // VARIABLE
  const columns: ColumnsType<CustomerList> = [
    {
      key: 'title',
      render: (val: string, row) => (
        <ListUnstyled>
          <li>
            <Link to={`customers/${row.id}`}>{row.getLongTitle()}</Link>
          </li>
          {row.address && (
            <li>
              <span role="img" aria-label="address">
                &#128205;
              </span>{' '}
              {row.address}
            </li>
          )}
          {row.phones && !!row.phones.length && (
            <li>
              <span role="img" aria-label="phone">
                &#128241;
              </span>{' '}
              <Typography.Text ellipsis style={{ width: 'calc(100vw * 0.16)' }}>
                {row.phones.map((r, i) => (
                  <Fragment key={`${i}-${r}`}>
                    {i !== 0 && ', '}
                    <a href={`tel:${r}`} className="tel">
                      {r}
                    </a>
                  </Fragment>
                ))}
              </Typography.Text>
            </li>
          )
          }
          {
            row.car_title && !!row.car_title.length && (
              <li>
                <span role="img" aria-label="car">
                  &#128664;
              </span>{' '}
                <Typography.Text ellipsis style={{ width: 'calc(100vw * 0.16)' }}>
                  {row.car_title.join(', ')}
                </Typography.Text>
              </li>
            )
          }
          {
            row.email && (
              <li>
                <span role="img" aria-label="email">
                  &#128231;
              </span>{' '}
                <a
                  href={`mailto:${row.email}`}
                  style={{ color: 'rgba(0, 0, 0, 0.65)' }}
                >
                  {row.email}
                </a>
              </li>
            )
          }
        </ListUnstyled >
      ),
      title: t('customer.listing.columns.title'),
    },
    {
      key: 'detail',
      render: (val: string, row) => {
        if (!row.service_summarize) return null;

        const {
          free,
          paid,
          unpaid,
        } = row.service_summarize as ICustomerListServices;
        return (
          <ListUnstyled>
            <li>
              {`${t('customer.listing.dataSource.detail.paid')}: ${paid}`}
            </li>
            <li>
              {`${t('customer.listing.dataSource.detail.unpaid')}: ${unpaid}`}
            </li>
            <li>
              {`${t('customer.listing.dataSource.detail.free')}: ${free}`}
            </li>
          </ListUnstyled>
        );
      },
      title: t('customer.listing.columns.detail'),
    },
    {
      key: 'lastService',
      render: (val: string, row) => {
        if (!row?.service_summarize) return null;

        const { last } = row?.service_summarize as ICustomerListServices;
        return getDateFormat(last);
      },
      title: t('customer.listing.columns.lastService'),
    },
  ];

  // METHOD
  const handleTableChange = (params: BaseListInput) => {
    dispatch.customer.getList(params);
  };

  return (
    <Card>
      <DataTable<CustomerList>
        button={
          <Button
            type="primary"
            onClick={() => history.push('/customers/create')}
          >
            <PlusOutlined />
            {t('customer.listing.button.create')}
          </Button>
        }
        columns={columns}
        dataSource={data}
        filter={{
          search: filter.search,
        }}
        loading={loading}
        name="customers"
        onChange={handleTableChange}
        pagination={pagination}
        rowKey="id"
      />
    </Card>
  );
}

export default memo(withRouter(CustomerListing));
