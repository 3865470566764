export const formHorizontal = {
  button: {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 12, offset: 6 },
    },
  },
  input: {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  },
};
