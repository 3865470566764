import services from 'assets/services.json';

export class Service {
  readonly service?: string;
  readonly children?: {
    readonly code: string;
    readonly s: number;
    readonly m: number;
    readonly l: number;
    readonly xl: number;
  }[];
  constructor(args?: Partial<Service>) {
    this.service = args?.service;
    this.children = args?.children;
  }
}

export function NewServices(): Service[] {
  return services.map((r) => new Service(r));
}
