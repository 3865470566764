import { memo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import CustomerDetail from 'presentation/pages/customer_detail';
import Dashboard from 'presentation/pages/dashboard';

function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route exact path="/customers/create" component={CustomerDetail} />
      <Route exact path="/customers/:id" component={CustomerDetail} />
      <Redirect to="/" />
    </Switch>
  );
}

export default memo(Routes);
