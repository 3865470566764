import { MessageApi } from 'antd/lib/message';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { i18n } from 'i18next';
import { keys } from 'lodash';

interface IHttpClientRequest {
  data?: any;
  file?: boolean;
  method: string;
  url: string;
}

export default class HttpClient {
  private service: AxiosInstance;
  private messageApi: MessageApi;
  private i18n: i18n;
  constructor(_messageApi: MessageApi, _i18n: i18n) {
    this.messageApi = _messageApi;
    this.i18n = _i18n;
    let service = axios.create();
    service.interceptors.response.use(
      (res) => res,
      (err) => {
        let message = err.response.data?.status?.message;
        if (!message) {
          message = this.i18n.t('error.api.8900');
        }
        this.messageApi.error({
          content: this.i18n.t(message),
          key: 'apis-error',
        });

        return Promise.reject(err.response);
      }
    );
    this.service = service;
  }

  request<T>(config: IHttpClientRequest) {
    // MAIN CONFIG
    const axiosConfig: AxiosRequestConfig = {
      baseURL: process.env.REACT_APP_API_ENDPOINT,
      headers: {},
      method: config.method as AxiosRequestConfig['method'],
      url: config.url,
      withCredentials: true,
    };

    // HEADER TOKEN
    if (localStorage.getItem('token')) {
      axiosConfig.headers['Authorization'] = `Bearer ${localStorage.getItem(
        'token'
      )}`;
    }

    // EXTEND DATA
    const data = {
      platform: 'website',
      ...(config.data || {}),
    };

    // METHOD STUFF
    if (config.method === 'GET') {
      axiosConfig.params = data;
      axiosConfig.params.t = Date.now();
    } else if (config.file) {
      const formData = new FormData();
      keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      axiosConfig.data = formData;
    } else {
      axiosConfig.data = data;
    }

    return this.service.request<T>(axiosConfig);
  }
}
