import moment from 'moment';
import i18n from 'infrastructure/interfaces/adapters/i18n';
import 'moment/locale/th';

export const getDateFormat = (val: any, format: string = 'LLL'): string => {
  if (!val) {
    return '';
  }

  let date = undefined;
  if (moment.isMoment(val)) {
    date = val;
  } else {
    date = moment(val);
  }

  date.locale(i18n.language);

  return date.format(format);
};
